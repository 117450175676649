<template>
  <v-container>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-form v-if="issue.status" ref="issuesForms" name="issuesForms" @submit.prevent="saveIssue()" class="form form--login grid">
      <v-row>
        <!-- Titel -->
        <div class="show-inline bottom-margin">
          <h1 v-html="$t('issues_title')"></h1>
          <h1>: {{ this.issue['subject' + $route.params.upLang] }}</h1>
        </div>
      </v-row>
      <v-row>
        <v-col cols="6">
          <!-- Typ -->
          <v-text-field type="text" disabled id="issue_edit_type" :label="$t('issue_edit_type')" :value="showIssueType(issue.type)" />
          <!-- Status -->
          <v-select
            id="issues_edit_status"
            v-model="issue.status"
            :label="$t('issues_edit_status')"
            :items="issueStatuses"
            :item-title="this.$getLangKey()"
            item-value="name"
          />
          <!-- Betreff -->
          <v-text-field type="text" id="issue_edit_subject" :label="$t('issue_edit_subject')" v-model="issue['subject' + $route.params.upLang]" />
          <!-- Text -->
          <v-textarea filled id="issue_edit_text" :label="$t('issue_edit_text')" v-model="issue['bodyText' + $route.params.upLang]"></v-textarea>
          <!-- Link -->
          <h6 v-html="$t('issues_edit_display_link')"></h6>
          <div id="links" class="link-container">
            <a v-if="issue.displayLink" @click="goToLink(issue.displayLink)" class="link-left">{{ issue.displayLink }} </a>
            <a v-else @click="goToLink(issue.reference)" class="link-left">{{ issue.reference }} </a>
          </div>
          <!-- Absender -->
          <div class="top-margin">
            <v-text-field type="text" disabled id="issue_edit_sender" :label="$t('issue_edit_sender')" v-model="issue.sender['title' + $route.params.upLang]" />
          </div>
          <!-- Empfänger -->
          <div class="search-inline" @click="searchReceiver()">
            <v-text-field
              id="issue_edit_receiver"
              class="padding-right"
              v-model="issue.recipient['title' + $route.params.upLang]"
              :label="$t('issue_edit_receiver')"
              type="text"
              item-value="id"
              item-text="recipient.id"
              clearable
              readonly
            />
            <v-btn color="primary" class="button-margin"><v-icon>mdi-account-search-outline</v-icon></v-btn>
          </div>
          <!-- Zugewiesene Person - responsible person -->
          <v-select
            id="issue_edit_assigned_person"
            :disabled="!issue.recipient"
            v-model="issue.responsibleUser"
            :label="$t('issue_edit_assigned_person')"
            :items="responsibleUsers"
            item-title="name"
            item-value="id"
            clearable
          />
          <!-- Fälligkeitsdatum -->
          <dbmDatePicker :dateLabel="$t('issue_edit_due_date')" v-model="issue.dueDate" :clearable="true"></dbmDatePicker>
        </v-col>
        <v-col cols="6">
          <!-- Bemerkungen -->
          <v-textarea filled id="issue_edit_remark_text" :label="$t('issue_edit_remark_text')" v-model="issue.remark"></v-textarea>
          <!-- guid search link -->
          <a href class="link-left" @click.prevent="goToSiblingGuidSearch()" v-html="$t('issue_edit_sibling_guid_link')"></a>
        </v-col>
      </v-row>
      <!-- Buttons -->
      <v-row>
        <div class="aligned-buttons">
          <v-btn color="primary" class="shadow-none" type="submit"><span v-html="$t('save')" /></v-btn>
          <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('cancel')" /></v-btn>
        </div>
      </v-row>
    </v-form>

    <!-- Empfänger Dialog -->
    <v-dialog :retain-focus="false" v-model="dialog" max-width="75%" id="masterdata-reciever-dialog">
      <v-card>
        <v-card-title><span v-html="$t('search_issues_search_person')" /></v-card-title>
        <v-card-text>
          <!-- search-cards-->
          <v-row class="row search spacer-md">
            <v-col cols="6" md="4" xl="4">
              <search-application-user ref="search-application-user" @search="search" />
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <search-contact ref="search-contact" @search="search" />
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <search-legal-entity ref="search-legal-entity" @search="search" />
            </v-col>
          </v-row>
          <v-btn color="primary" @click="search"><span v-html="$t('masterdata_search')" /></v-btn>
          <v-btn color="secondary" @click="resetSearch"><span v-html="$t('masterdata_reset')" /></v-btn>
          <!-- Search Result table-->
          <v-data-table-server
            v-model="selected"
            item-value="rowKey"
            :headers="fields"
            :items="items"
            :fields="fields"
            show-select
            select-strategy="single"
            :loading="dialogsettings.loading"
          >
          </v-data-table-server>
        </v-card-text>
        <v-card-actions>
          <v-btn id="submit-btn" class="m-2" color="primary" @click="submit()" type="submit"><span v-html="$t('masterdata_dairies_add_button')" /></v-btn>
          <v-btn id="close-btn" class="m-2" color="secondary" @click="closeDialog()" type="submit"><span v-html="$t('close')" /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import _ from 'lodash'
import SearchLegalEntity from '../../components/searchCards/searchLegalEntity'
import SearchContact from '../../components/searchCards/searchContact'
import SearchApplicationUser from '../../components/searchCards/searchApplicationUser'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'
let searchApplicationUserStore = useSearchApplicationUserStore()
import { Term } from '@/services/term'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { DTSHeader } from '@/services/BackendService'
import { useIssueStatusesStore } from '@/store/enums/IssueStatusesStore'
import { useIssueTypesStore } from '@/store/enums/IssueTypesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'issues_edit',
  props: {},
  data() {
    return {
      loading: false,

      issue: {},
      selected: [],
      items: [],
      dialog: false,
      totalElements: 0,
      dialogsettings: { loading: false, totalElements: 0 },
      fields: <DTSHeader[]>[
        {
          title: this.$t('search_issues_application_users_legal_entity_name1'),
          key: 'legalEntityName1',
          sortable: false,
          width: '50%'
        },
        {
          title: this.$t('search_issues_application_users_legal_entity_name2'),
          key: 'legalEntityName2',
          sortable: false,
          width: '50%'
        }
      ],
      responsibleUsers: []
    }
  },
  computed: {
    issueTypes() {
      return useIssueTypesStore().issueTypes
    },
    issueStatuses() {
      return useIssueStatusesStore().issueStatuses
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(searchApplicationUserStore, searchApplicationUserStore.items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    SearchLegalEntity,
    DbmDatePicker,
    SearchContact,
    SearchApplicationUser
  },
  methods: {
    goToSiblingGuidSearch() {
      this.$router
        .push({
          name: 'issues_read',
          query: {
            size: 10
          },
          params: {
            siblingGuid: this.issue.siblingGuid
          }
        })
        .catch((err) => {
          // duplicated route
        })
    },
    async loadIssue() {
      try {
        const result = await this.axios.get(apiURL + '/issues/' + this.$route.params.id, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.issue = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async loadResponsibleUsers() {
      this.responsibleUsers = []
      try {
        const response = await this.axios.get(apiURL + '/applicationUsers/findByLegalEntityId', {
          params: {
            legalEntityId: this.issue.recipient?.id ? this.issue.recipient?.id : this.issue.recipient.legalEntityId
          }
        })
        if (await response) {
          const responsibleUsers = response.data
          this.responsibleUsers = Array.isArray(responsibleUsers) ? responsibleUsers : []
        }
      } catch (e) {
        this.items = []
        showError(e)
      }
    },
    showIssueType(type) {
      if (type) {
        let issueType = this.issueTypes.filter((el) => el?.name === type)[0]
        return issueType[this.$getLangKey()]
      } else return ''
    },
    async saveIssue() {
      let reducedIssue = _.omit(this.issue, ['link'])
      try {
        const result = await this.axios.patch(
          apiURL + '/issues/' + this.$route.params.id,
          {
            ...reducedIssue,
            sender: { id: reducedIssue.sender.id },
            recipient: {
              id: reducedIssue.recipient.legalEntityId ? reducedIssue.recipient.legalEntityId : reducedIssue.recipient.id
            },
            responsibleUser: reducedIssue.responsibleUser
              ? {
                  id: reducedIssue.responsibleUser.id ? reducedIssue.responsibleUser.id : reducedIssue.responsibleUser
                }
              : null
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.$router
            .push({
              name: 'issues_read'
            })
            .catch((e) => {
              /* duplicated route */
            })
        }
      } catch (e) {
        showError(e)
      }
    },
    cancel() {
      this.$router
        .push({
          name: 'issues_read'
        })
        .catch((err) => {
          // duplicated route
        })
    },
    searchReceiver() {
      this.selected = []
      this.dialog = true
      this.loading = false
    },
    submit() {
      this.issue.recipient = this.selected[0]
      this.issue.recipient['title' + this.$getUpLang()] = this.selected[0]?.legalEntityName1
      this.dialog = false
    },
    closeDialog() {
      this.items = []
      this.dialog = false
    },
    resetSearch() {
      this.selected = []
      this.resetCards()
    },
    resetCards() {
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-application-user'].reset()
    },
    // dialogSearch
    async search() {
      this.dialogsettings.loading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/searchLegalEntity', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.dialogsettings.totalElements = await response.data.totalElements
        this.items.forEach((item, i) => {
          item.rowKey = i + 1
        })
      } catch (e) {
        this.items = []
        showError(e)
      } finally {
        this.dialogsettings.loading = false
      }
    },
    goToLink(item) {
      window.open(item, '_blank')
    }
  },

  watch: {
    'issue.recipient': async function () {
      await this.loadResponsibleUsers()
    }
  },
  beforeMounted() {
    useIssueStatusesStore().fetchIssueStatuses()
    useIssueTypesStore().fetchIssueTypes()
  },
  async mounted() {
    await this.loadIssue()
  }
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.show-inline {
  display: flex;
  text-align: left;
}

.clear-button:hover {
  cursor: pointer;
}

.bottom-margin {
  margin-bottom: $spacer-sm;
}

.button-margin {
  margin: -3px !important;
}

.button-margin-right {
  margin-right: $spacer-sm !important;
}

.top-margin {
  margin-top: $spacer-xxs;
}

.padding-right {
  padding-right: $spacer-xxs;
}

.search-inline {
  display: flex;
}
</style>
